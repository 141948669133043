import React, { useRef, useEffect } from "react";
import "./Origin.css";
import { Container } from "react-bootstrap";
import { Agroeiravideo } from "../../assets";

export default function Origin() {
  const videoRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.4, // Change this value depending on when you want the video to start playing
    };

    const observer = new IntersectionObserver(handleVideoIntersect, options);
    if (videoRef.current) observer.observe(videoRef.current);

    return () => {
      if (videoRef.current) observer.unobserve(videoRef.current);
    };
  }, []);

  const handleVideoIntersect = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.play();
      } else {
        entry.target.pause();
      }
    });
  };
  
  return (
    <section id="origin">
      <Container>
        <div className="content">
          <div className="text">
            <h1 className="title">A nossa história</h1>
            <h3 className="desc">
            Começamos com uma visão simples: cultivar produtos excepcionais. 
            A nossa paixão pela agricultura nasceu em pequenos terrenos, e cresceu até alcançar os 100 hectares de terra fértil. 
            Desde então, temos orgulho em oferecer produtos de qualidade e serviços especializados, trazendo o melhor da terra dia-a-dia."{" "}
            </h3>
          </div>
          <div className="image">
          <video ref={videoRef} width="100%" height="auto" alt="AGROEIRA VIDEO" muted controls loop>
              <source src={Agroeiravideo} type="video/mp4"/>
            </video>
          </div>
        </div>
      </Container>
    </section>
  );
}
