import React from "react";
import { withRouter } from "react-router";
import "./Sidebar.css";
import { closeburger, arrowRight } from "../../assets";

const Side = ({ toggleChange }) => {
  return (
    <>
      <article className="sidebar">
        <div className="content">
          <div className="top-section">
            <button className="btn btn-connect">Login</button>
            <img
              src={closeburger}
              alt="toggle"
              onClick={toggleChange}
              className="toggleBtn"
            />
          </div>

          <div className="links">
            <a className="link" href="#overview" >
              <h2>Visão Geral</h2>
              <img src={arrowRight} alt="arrow"/>
            </a>
            <a className="link"onClick={toggleChange} href="#faq">
              <h2>FAQs</h2>
              <img src={arrowRight} alt="arrow" />
            </a>
          </div>
          <div className="mid-text">
            <p className="bigtext">
              Agro Eira, Agricultura e Turismo{" "}
            </p>
          </div>
          <div className="footer-btns">
            <DownloadPriccing/>
          </div>
        </div>
      </article>
    </>
  );
};

const DownloadPriccing = props => {
  const downloadFile = () => {
    window.location.href = "https://firebasestorage.googleapis.com/v0/b/agroeira-5616b.appspot.com/o/Agroeira%20Pre%C3%A7%C3%A1rio%20de%20Servi%C3%A7os.pdf?alt=media&token=3ca41a77-1226-4120-b52d-d9893b654557"
  }
  return (
    <button className="btn btn-mint" onClick={downloadFile}>Serviços</button>
  )
}

const Sidebar = withRouter(Side);
export default Sidebar;
