import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Pages/Home/Home";
import {app, analytics } from "./firebase.js";


const firebaseConfig = {
  apiKey: "AIzaSyB-mcQo1AEdzy1w_TjQ8vHlVv0oUitJ_xM",
  authDomain: "agroeira-5616b.firebaseapp.com",
  projectId: "agroeira-5616b",
  storageBucket: "agroeira-5616b.appspot.com",
  messagingSenderId: "425016402046",
  appId: "1:425016402046:web:44312f1818c6357ccf782f",
  measurementId: "G-55F9JGJMJF"
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
