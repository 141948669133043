import React from "react";
import "./Header.css";
import {Agroeiralogo_white_shorter_vector, logo, hamburger, closeburger } from "../../assets";
import { Nav, Container } from "react-bootstrap";
import styled from "styled-components";
import Sidebar from "../Sidebar/Sidebar";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

export default function Header() {
  const ToggleSwitch = styled.div`
    .toggleBtn {
      display: none;
    }

    @media (max-width: 768px) {
      .toggleBtn {
        display: unset;
        padding-right: 1rem;
        transition: all 0.3s ease;
        z-index: 3;
      }
      .logo {
        z-index: -2;
      }
      .toggleBtn:hover {
        cursor: pointer;
      }
      .btns {
        display: none;
      }
    }
  `;

  const [toggle, setToggle] = React.useState(false);
  const toggleChange = (e) => {
    e.preventDefault();
    setToggle(!toggle);
  };

  const DownloadPriccing = props => {
    const downloadFile = () => {
      window.location.href = "https://firebasestorage.googleapis.com/v0/b/agroeira-5616b.appspot.com/o/Agroeira%20Pre%C3%A7%C3%A1rio%20de%20Servi%C3%A7os.pdf?alt=media&token=3ca41a77-1226-4120-b52d-d9893b654557"
    }
    return (
      <button className="btn btn-mint" onClick={downloadFile}>Serviços</button>
    )
  }

  /* 
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  auth.useDeviceLanguage();
  signInWithPopup(auth, provider)
  .then((result) => {*/
    // This gives you a Google Access Token. You can use it to access the Google API.
    /*const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;*/
    // The signed-in user info.
    /*const user = result.user;*/
    // IdP data available using getAdditionalUserInfo(result)
    // ...
    /*}).catch((error) => {*/
    // Handle Errors here.
    /*const errorCode = error.code;
    const errorMessage = error.message;*/
    // The email of the user's account used.
    /*const email = error.customData.email;*/
    // The AuthCredential type that was used.
    /*const credential = GoogleAuthProvider.credentialFromError(error);*/
    // ...
    /*});*/

  return (
    <div id="header">
      <Container>
        <div className="content">
          <Nav className="nav">
            <div className="logo">
              <img src={Agroeiralogo_white_shorter_vector} alt="AGROEIRA LOGO" width="250" style={{ backgroundColor: 'transparent' }}/>
            </div>
            
            <ToggleSwitch>
              <div className="toggleBtn">
                {!toggle ? (
                  <img src={hamburger} alt="toggle" onClick={toggleChange} />
                ) : (
                  <img src={closeburger} alt="toggle" onClick={toggleChange} />
                )}
              </div>
              <div className="btns">
                <DownloadPriccing/>
                <button className="btn btn-connect">Login</button>
              </div>
            </ToggleSwitch>
            {toggle && (
              <Sidebar setToggle={setToggle} toggleChange={toggleChange} />
            )}
          </Nav>
          <div className="centerText">
            <h1 className="title">
              "Entre vinhas e letras,
              <br /> o sabor da vida."
            </h1>
            <h3 className="desc">Fernando Pessoa</h3>
            <hr className="vertical-line" />
          </div>
        </div>
      </Container>
    </div>
  );
}
