import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import { slider1, slider2, slider3, slider4 } from "../../assets";
import "./Carousel.css";
import { Agroeiravideo1, Agroeiravideo2, Agroeiravideo3, Agroeiravideo4, Agroeiravideo5, Agroeiravideo6 } from "../../assets";

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);



export default function Carousel() {
  return (
    <section id="carousel">
      <Swiper
        slidesPerView={1.5}
        spaceBetween={40}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <video style={{ width: '100%', height: '100%', objectFit: 'cover', paddingBottom: '40px' }}
            autoPlay 
            muted 
            controls
            loop>
              <source src={Agroeiravideo3} type="video/mp4"/>
          </video>
        </SwiperSlide>
        <SwiperSlide>
          <video style={{ width: '100%', height: '100%', objectFit: 'cover', paddingBottom: '40px' }}
            autoPlay 
            muted 
            controls
            loop>
              <source src={Agroeiravideo4} type="video/mp4"/>
          </video>
        </SwiperSlide>
        <SwiperSlide>
          <video style={{ width: '100%', height: '100%', objectFit: 'cover', paddingBottom: '40px' }}
            autoPlay 
            muted 
            controls
            loop>
              <source src={Agroeiravideo5} type="video/mp4"/>
          </video>
        </SwiperSlide>
        <SwiperSlide>
          <video style={{ width: '100%', height: '100%', objectFit: 'cover', paddingBottom: '40px' }}
            autoPlay 
            muted 
            controls
            loop>
              <source src={Agroeiravideo6} type="video/mp4"/>
          </video>
        </SwiperSlide>
      </Swiper>
    </section>
  );
}
