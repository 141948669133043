import React from "react";
import "./Community.css";
import { Container } from "react-bootstrap";
import { communityImg, appstore, playstore } from "../../assets";

export default function Community() {
  return (
    <section id="community">
      <Container>
        <div className="content">
          <div className="text">
            <h1 className="title">A Revolução Tecnológica na Agricultura chega com a nossa nova App!</h1>
            <div className="grid-section">
            <div className="text">
                <img src={appstore} style={{ width: "52%", height: "52%"}} />
                <br />
                <img src={playstore} style={{ width: "50%", height: "50%", paddingLeft: "2.5%"}}/>
                
              </div>
              <h2 className="desc">
              A nossa nova aplicação que trará avanços tecnológicos inéditos para o mundo da agricultura. 
              Com recursos inovadores e soluções de vanguarda, a nossa app está destinada a transformar e otimizar o modo como os agricultores trabalham, promovendo maior eficiência, sustentabilidade e produtividade.
               Fique atento para mais informações sobre o lançamento e descubra como pode elevar a sua produção agrícola para um novo patamar de excelência
              </h2>
              
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
