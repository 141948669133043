import React from "react";
import "./Accordian.css";
import { Accordion } from "react-bootstrap";
import { valedotualogo, fragadoaltologo, polilogo } from "../../assets";

export default function Accordian({ theme }) {
  return (
    <>
      {theme ? (
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0" className="faq-accordian-item">
            <Accordion.Header>Projeto: "PDR2020-10211-095680"</Accordion.Header>
            <Accordion.Body className="white-body">
            Olival e reabilitação de armazéns agrícolas
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="faq-accordian-item">
            <Accordion.Header>Projeto: "PDR2020-322-086636"</Accordion.Header>
            <Accordion.Body>
            Olival biológico e tradicional com rega
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="faq-accordian-item">
            <Accordion.Header>Projeto: "PDR2020-321-083830"</Accordion.Header>
            <Accordion.Body>
            Olival biológico e tradicional com rega
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
            Vale do Tua
            </Accordion.Header>
            <Accordion.Body>
            Associação Económica Patronal não Cooperativa, com sede em Mirandela, que iniciou a sua atividade em 1992 nascendo da vontade dos seus fundadores em criarem uma estrutura inovadora na região, com a missão de contribuir para o desenvolvimento sustentado da mesma, dotando os agentes do setor agrário de formação adequada, promovendo também a inovação e o espírito empreendedor, a modernização, a melhoria técnica-económica e o aumento do rendimento das explorações agrícolas dos associados.
O Centro de Gestão da Empresa Agrícola Vale do Tua é uma instituição de apoio aos agricultores, indo de encontro às suas necessidades e dificuldades. Focalizando o nosso apoio a nível técnico e burocrático.
<br />
            <img src={valedotualogo} width="250" style={{ backgroundColor: 'transparent' }}/>
            <br />
            <a href="https://www.valedotua.com/">https://www.valedotua.com/</a>
            </Accordion.Body>
          </Accordion.Item>
          {/*COMMENT 
          <Accordion.Item eventKey="1">
            <Accordion.Header>Fraga do Alto</Accordion.Header>
            <Accordion.Body>
            A Fraga do Alto Lda é uma empresa de referência no setor agrícola que privilegia a relação com os seus clientes e a qualidade dos seus produtos e dos seus serviços, afirmando-se como uma marca de referência. Actualmente dão assistencia a mais de 350  hectares as exploraçoes dos nossos clientes.
            <br />
            <img src={fragadoaltologo} width="250" style={{ backgroundColor: 'transparent' }}/>
            <br />
            <a href="https://www.fragadoalto.com/">https://www.fragadoalto.com/</a>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Instituto Politécnico de Bragança</Accordion.Header>
            <Accordion.Body>
            Os laboratórios da ESA efectuam determinações analíticas diversas, incluindo parâmetros físico-químicos, microbiológicos e outros, em alimentos, águas, resíduos, solos e plantas. Os laboratórios dão resposta a solicitações isoladas, de pessoas individuais, e, preferencialmente, a entidades que estabelecem protocolos com a ESA onde são especificadas as condições dos serviços prestados.
            <br />
            <img src={polilogo} width="250" style={{ backgroundColor: 'transparent' }}/>
            <br />
            <a href="https://esa.ipb.pt/apoio-a-comunidade/analises-e-servicos/">https://esa.ipb.pt/apoio-a-comunidade/analises-e-servicos/</a>
            </Accordion.Body>
          </Accordion.Item>
          */}
        </Accordion>
      )}
    </>
  );
}
