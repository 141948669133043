import React from "react";
import "./Overview.css";
import { Container } from "react-bootstrap";
import overviewGridImg from "../../assets/overviewGridImg.png";
import Carousel from "../Carousel/Carousel";
import { certislogo,biolog_logo } from "../../assets";


export default function Overview() {
  return (
    <section id="overview">
      <Container fluid>
        <div className="content">
          <div className="top-text">
            <h1 className="title">Visão geral</h1>
            <div className="desc">
            A AgroEira é uma empresa de destaque no setor agrícola, comprometida com a excelência dos seus produtos, serviços e relacionamento com os clientes, posicionando-se como uma marca de referência. 
            Além das soluções integradas e personalizadas que oferecemos para maximizar o investimento, fornecemos serviços de cartografia aérea e videovigilância de culturas, diferenciando-nos no mercado.
            </div>
          </div>

          {/*COMMENT */}

          <div className="grid-section">
            <div className="text">
              <h5 className="title">Qualidade</h5>
              <h3 className="subtitle">Excelência em Produção Biológica Certificada pela Certis</h3>
              <p className="desc">
              Somos uma empresa reconhecida e certificada pela Certis no cultivo e produção de alimentos biológicos. 
              Comprometidos com práticas agrícolas sustentáveis e ecologicamente responsáveis, asseguramos produtos de qualidade superior, 
              preservando a saúde do ecossistema e proporcionando opções saudáveis e seguras para os consumidores conscientes..{" "}
              </p>
            </div>
            <img src={certislogo} alt="img grid" style={{ width: "50%", height: "auto", aspectRatio: "auto",paddingTop: "25%"}}/>
          </div>

          <div className="grid-section">
            <img src={biolog_logo} alt="img grid" style={{ width: "50%", height: "auto", aspectRatio: "auto",paddingTop: "25%"}}/>
            <div className="text">
              <h5 className="title">Qualidade</h5>
              <h3 className="subtitle">Parceria Inspiradora com Biolog: Alimentação Sustentável e Regenerativa</h3>
              <p className="desc">
              Estamos orgulhosos de nossa parceria com a Biolog, líder em produção alimentar sustentável e regenerativa. Em sintonia com a visão do Relatório Brundtland de 1987, a Biolog não apenas satisfaz as necessidades atuais, mas também busca constantemente regenerar, restaurar e aperfeiçoar. Juntos, estamos moldando um futuro alimentar mais equilibrado e promissor.
              </p>
            </div>
            
          </div>

        </div>
      </Container>
      
    </section>
  );
}
