import React from "react";
import "./Footer.css";
import { Container } from "react-bootstrap";
import { Agroeiralogo_black_shorter,Finpdr2020,Finpt2020,Finue } from "../../assets";

export default function Footer() {
  return (
    <footer id="footer">
      <Container>
        <div className="content">
          <div className="logo">
              <img src={Agroeiralogo_black_shorter} alt="agroeira" width="250" />
              <p className="footer-text">
              <br />Direitos Reservados © 2023 AgroEira Lda.
              </p>
          </div>

          <div className="logosfin">
              <p className="footer-text">
                Contactos:
                <br /> empresa.agroeira@gmail.com
                <br /> +351 938 699 998
              </p>
              
          </div>


          <div className="logosfin">
              <p className="footer-text">
                Financiamento:
              </p>
              <img src={Finpdr2020} width="200" /> <br />
              <img src={Finpt2020} width="200" style={{ paddingTop: "10%" }}/> <br />
              <img src={Finue} width="200" style={{ paddingTop: "10%" }}/>
              
          </div>
        </div>
      </Container>
    </footer>
  );
}
